<template>
	<div>
		<v-row dense>
			<v-col cols="3">
				<b>
					Statement Translation ID:
				</b>
			</v-col>
			<v-col cols="9">
				{{ statementTranslation.svid }}
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<b>
					Language ID:
				</b>
			</v-col>
			<v-col cols="9">
				{{ statementTranslation.languageId }}
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<b>
					Language Code:
				</b>
			</v-col>
			<v-col cols="9">
				{{ statementTranslation.languageCode }}
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<b>
					Language Name:
				</b>
			</v-col>
			<v-col cols="9">
				{{ statementTranslation.languageName }}
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<b>
					Statement Text:
				</b>
			</v-col>
			<v-col cols="9">
				{{ statementTranslation.statementText }}
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	props: {
		statementTranslation: {
			type: Object,
			required: true
		}
	}
}
</script>
