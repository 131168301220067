<template>
	<SectionCard>
		<template #title>
			{{ title }}
		</template>
		<template #title-action>
			<v-spacer />
			<IconButton @click="$emit('close')">
				mdi-close
			</IconButton>
		</template>
		<template #body>
			<ChannelAdditionalInformation
				v-if="itemToDisplay.type === consentStructureTypes.CHANNEL"
				:channel="itemToDisplay"
			/>
			<StatementAdditionalInformation
				v-else-if="itemToDisplay.type === consentStructureTypes.STATEMENT"
				:statement="itemToDisplay"
			/>
			<StatementTranslationAdditionalInformation
				v-else-if="itemToDisplay.type === consentStructureTypes.STATEMENT_TRANSLATION"
				:statement-translation="itemToDisplay"
			/>
		</template>
	</SectionCard>
</template>
<script>
import SectionCard from '../../../../../shared/components/section-card.vue'
import IconButton from '../../../../../shared/components/icon-button.vue'
import ChannelAdditionalInformation from './channel-additional-information.vue'
import StatementAdditionalInformation from './statement-additional-information.vue'
import StatementTranslationAdditionalInformation from './statement-translation-additional-information.vue'
export default {
	components: {
		SectionCard,
		IconButton,
		ChannelAdditionalInformation,
		StatementAdditionalInformation,
		StatementTranslationAdditionalInformation
	},
	props: {
		itemToDisplay: {
			type: Object,
			required: true
		},
		consentStructureTypes: {
			type: Object,
			required: true
		}
	},
	computed: {
		title () {
			switch (this.itemToDisplay.type) {
				case this.consentStructureTypes.CHANNEL:
					return 'Channel'
				case this.consentStructureTypes.STATEMENT:
					return 'Statement'
				case this.consentStructureTypes.STATEMENT_TRANSLATION:
					return 'Statement Translation'
				default:
					return ''
			}
		}
	}
}
</script>
