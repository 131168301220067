<template>
	<div>
		<v-row dense>
			<v-col cols="3">
				<b>
					Statement ID:
				</b>
			</v-col>
			<v-col cols="9">
				{{ statement.statementID }}
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<b>
					Is Opt In:
				</b>
			</v-col>
			<v-col cols="9">
				{{ statement.isOptIn }}
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<b>
					Default Value:
				</b>
			</v-col>
			<v-col cols="9">
				{{ statement.defaultValue }}
			</v-col>
		</v-row>
		<v-row dense>
			<v-col cols="3">
				<b>
					Statement Text:
				</b>
			</v-col>
			<v-col cols="9">
				{{ statement.statementText }}
			</v-col>
		</v-row>
		<v-row
			v-if="statement.metaData.length > 0"
			dense
		>
			<v-col cols="3">
				<b>
					Metadata:
				</b>
			</v-col>
			<v-col cols="9">
				<span
					v-for="(metaData, index) in statement.metaData"
					:key="index"
					class="mr-2"
				>
					<v-chip
						class="mb-2"
						small
					>
						<span>
							<strong>
								{{ metaData.metaDataKeyName }}:
							</strong>
							{{ metaData.metaDataValue }}
						</span>
					</v-chip>
				</span>
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	props: {
		statement: {
			type: Object,
			required: true
		}
	}
}
</script>
