<template>
	<ViewLayout>
		<template #header-title>
			Consent Structure
		</template>
		<template #header-caption>
			A diagram depicting the current setup of all active Cassie Channels & Statements
		</template>
		<template #content>
			<v-row dense>
				<v-col cols="12">
					<FilterBar
						:selected-brand-id.sync="selectedBrandId"
						hide-search-query-filter
						@clearFilters="clearFilters"
					>
						<template #after-filters>
							<TextField
								v-model="searchQuery"
								label="Search"
							/>
						</template>
					</FilterBar>
				</v-col>
			</v-row>
			<v-row dense>
				<v-spacer />
				<v-col
					class="d-flex justify-center"
					cols="12"
				>
					<div class="cassie-horizontal-lg">
						<span>
							<v-icon class="binary-black--text text--lighten-2">
								mdi-web
							</v-icon>
							Brand
						</span>
						<span>
							<v-icon class="coral-red--text text--lighten-2">
								mdi-circle
							</v-icon>
							Parent Channel
						</span>
						<span>
							<v-icon class="highlight-pink--text text--lighten-2">
								mdi-circle
							</v-icon>
							Child Channel
						</span>
						<span>
							<v-icon class="warm-yellow--text text-lighten-1">
								mdi-note-text
							</v-icon>
							Statement
						</span>
						<span>
							<v-icon class="aqua-blue--text">
								mdi-translate
							</v-icon>
							Statement Translation
						</span>
					</div>
				</v-col>
				<v-spacer />
			</v-row>
			<v-row dense>
				<v-col :cols="!selectedItem ? 12 : 7">
					<SectionCard
						style="max-height: calc(100vh - 350px); overflow-y: scroll;"
						scroll-content
					>
						<template #title>
							Consent Structure
						</template>
						<template #title-action>
							<Toggle
								:value="expandAll"
								label="Expand All"
								@update:value="expandAllNodes($event)"
							/>
						</template>
						<template #body>
							<v-treeview
								ref="consentStructureTree"
								activatable
								dense
								:items="filteredConsentStructure"
								:search="searchQuery"
								:open-all="expandAll"
								item-key="id"
							>
								<template #label="{ item }">
									<div
										:class="item.type !== CONSENT_STRUCTURE_TYPES.BRAND ? 'clickable-node' : ''"
										@click="displayAdditionalInformation(item)"
									>
										<v-icon :class="item.color">
											{{ item.icon }}
										</v-icon>
										{{ item.name }}
									</div>
								</template>
							</v-treeview>
						</template>
					</SectionCard>
				</v-col>
				<v-col
					v-if="selectedItem"
					cols="5"
				>
					<ConsentStructureAdditionalInformationCard
						v-if="selectedItem"
						:item-to-display="selectedItem"
						:consent-structure-types="CONSENT_STRUCTURE_TYPES"
						@close="selectedItem = null"
					/>
				</v-col>
			</v-row>
		</template>
		<template #footer>
			<SecondaryActionButton @click="returnToPrevious">
				{{ returnToPreviousButtonText }}
			</SecondaryActionButton>
		</template>
	</ViewLayout>
</template>
<script>
import ViewLayout from '../../../../../shared/layouts/view-layout.vue'
import FilterBar from '../../../../../shared/components/filter-bar.vue'
import TextField from '../../../../../shared/components/text-field.vue'
import SectionCard from '../../../../../shared/components/section-card.vue'
import ConsentStructureAdditionalInformationCard from './consent-structure-additional-information-card.vue'
import SecondaryActionButton from '../../../../../shared/components/secondary-action-button.vue'
import Toggle from '../../../../../shared/components/toggle.vue'
import { getPreferenceChannels } from '../../../../../shared/utils/api/channels.js'
import { brandOptions } from '../../../../../shared/state/brands.js'
import { CHANNELS, STATEMENTS }	from '../../../router/route-names.js'
import { getTruncatedText } from '../../../../../shared/utils/utils.js'
export default {
	components: {
		ViewLayout,
		SectionCard,
		ConsentStructureAdditionalInformationCard,
		SecondaryActionButton,
		FilterBar,
		TextField,
		Toggle
	},
	props: {
		accessedVia: String
	},
	setup () {
		return {
			brandOptions
		}
	},
	data () {
		const CONSENT_STRUCTURE_TYPES = {
			BRAND: 1,
			CHANNEL: 2,
			STATEMENT: 3,
			STATEMENT_TRANSLATION: 4
		}
		return {
			channels: [],
			CONSENT_STRUCTURE_TYPES,
			selectedItem: null,
			selectedBrandId: null,
			searchQuery: '',
			expandAll: false
		}
	},
	computed: {
		brandItems () {
			const universalBrandItem = {
				value: 0,
				text: 'Universal'
			}

			if (this.brandOptions.find(({ value }) => value === 0)) return this.brandOptions
			else return [universalBrandItem, ...this.brandOptions]
		},
		parentChannels () {
			return this.channels.filter(({ channelType }) => channelType.typeID === 1).sort((a, b) => a.channelName.localeCompare(b.channelName))
		},
		nestedChannels () {
			return this.parentChannels.map(parentChannel => {
				const childChannels = this.channels.filter(({ channelType }) => channelType.parentChannelID === parentChannel.channelId).map(childChannel => {
					return {
						...childChannel,
						id: `${childChannel.channelId} ${childChannel.channelName}`,
						name: childChannel.channelName,
						icon: 'mdi-circle',
						color: 'highlight-pink--text text--lighten-2',
						type: this.CONSENT_STRUCTURE_TYPES.CHANNEL,
						children: childChannel.statements.map(statement => {
							return {
								...statement,
								id: `${statement.statementID} ${getTruncatedText(statement.statementText, 50)} ${statement.isOptIn ? '(Opt-In)' : '(Opt-Out)'}`,
								type: this.CONSENT_STRUCTURE_TYPES.STATEMENT,
								name: `${getTruncatedText(statement.statementText, 50)} ${statement.isOptIn ? '(Opt-In)' : '(Opt-Out)'}`,
								icon: 'mdi-note-text',
								color: 'warm-yellow--text text--lighten-1',
								children: this.getTranslations(statement.liveStatements)
							}
						})
					}
				})
				const childStatements = parentChannel.statements.map(statement => {
					return {
						...statement,
						type: this.CONSENT_STRUCTURE_TYPES.STATEMENT,
						id: `${statement.statementID} ${getTruncatedText(statement.statementText, 50)} ${statement.isOptIn ? '(Opt-In)' : '(Opt-Out)'}`,
						name: `${getTruncatedText(statement.statementText, 50)} ${statement.isOptIn ? '(Opt-In)' : '(Opt-Out)'}`,
						icon: 'mdi-note-text',
						color: 'warm-yellow--text text-lighten-1',
						children: this.getTranslations(statement.liveStatements)
					}
				})
				return {
					...parentChannel,
					id: `${parentChannel.channelId} ${parentChannel.channelName}`,
					name: `${parentChannel.channelName}`,
					icon: 'mdi-circle',
					color: 'coral-red--text text--lighten-2',
					type: this.CONSENT_STRUCTURE_TYPES.CHANNEL,
					children: [...childChannels, ...childStatements]
				}
			}).sort((a, b) => a.channelName.localeCompare(b.channelName)).flat()
		},
		treeviewStructure () {
			return this.brandItems.map(({ value, text }) => {
				return {
					id: `${value} ${text}`,
					brandId: value,
					name: text,
					type: this.CONSENT_STRUCTURE_TYPES.BRAND,
					icon: 'mdi-web',
					color: 'binary-black--text text--lighten-2',
					children: this.nestedChannels.filter(({ brandId }) => brandId === value)
				}
			})
		},
		filteredConsentStructure () {
			return this.treeviewStructure.filter(({ brandId }) => {
				let check = true
				if (this.selectedBrandId !== null) check = check && brandId === this.selectedBrandId
				return check
			})
		},
		returnToPreviousButtonText () {
			return this.accessedVia === 'statements' ? 'Back to Statements' : 'Back to Channels'
		}
	},
	async created () {
		await this.loadChannels()
	},
	methods: {
		async loadChannels () {
			const { data: { channels } } = await getPreferenceChannels()
			this.channels = channels
		},
		getTranslations (liveStatements) {
			return liveStatements.map(liveStatement => {
				return {
					...liveStatement,
					id: `${liveStatement.svid} ${getTruncatedText(liveStatement.statementText, 50)} (${liveStatement.languageName})`,
					name: `${getTruncatedText(liveStatement.statementText, 50)} (${liveStatement.languageName})`,
					icon: 'mdi-translate',
					color: 'aqua-blue--text',
					type: this.CONSENT_STRUCTURE_TYPES.STATEMENT_TRANSLATION
				}
			}).filter(({ languageId }) => languageId !== 0)
		},
		displayAdditionalInformation (item) {
			if (item.type === this.CONSENT_STRUCTURE_TYPES.BRAND) return
			this.selectedItem = item
		},
		returnToPrevious () {
			this.$router.push(this.accessedVia === 'statements' ? STATEMENTS : CHANNELS)
		},
		clearFilters () {
			this.searchQuery = ''
			this.channelNameSearchQuery = ''
			this.statementTextSearchQuery = ''
		},
		expandAllNodes (value) {
			this.expandAll = value
			this.$refs.consentStructureTree.updateAll(value)
		}
	}
}
</script>

<style>
.clickable-node:hover {
	cursor: pointer;
}
</style>
